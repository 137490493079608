import Payments from 'components/activity/payments/main/containers/PaymentsContainer';
import StandingOrder from 'components/activity/payments/standingOrder/main/containers/MainContainer';
import MakePayment from 'components/activity/payments/makePayment/containers/MakePaymentContainer';
import ManagePayees from 'components/activity/payments/managePayee/containers/ManagePayeesContainer';
import CreatePayee from 'components/activity/payments/createPayee/containers/CreatePayeeContainer';
import MakePaymentForm from 'components/activity/payments/uk/form/containers/MakePaymentFormContainer';
import UKPaymentSuccess from 'components/activity/payments/uk/success/containers/PaymentSuccessContainer';
import StandingOrderPayee from 'components/activity/payments/standingOrder/payee/containers/StandingOrderPayeeContainer';
import StandingOrderForm from 'components/activity/payments/standingOrder/form/containers/StandingOrderFormContainer';
import StandingOrderPaymentSuccess from 'components/activity/payments/standingOrder/success/containers/PaymentSuccessContainer';
import DirectDebitContainer from 'components/activity/payments/directDebit/containers/DirectDebitContainer';
import DirectDebitItemContainer from 'components/activity/payments/directDebit/containers/DirectDebitItemContainer';
import AddCash from 'components/activity/payments/addCash/presentational/AddCash';

const paymentsRoutes = [
    { path: '', component: Payments, pageTitle: 'Payments' },
    {
        path: 'standing-order/payee',
        component: StandingOrderPayee,
        pageTitle: 'Payee | Standing Order',
    },
    {
        path: 'standing-order/form',
        component: StandingOrderForm,
        pageTitle: 'Form | Standing Order',
    },
    {
        path: 'standing-order/success',
        component: StandingOrderPaymentSuccess,
        pageTitle: 'Status | Standing Order',
    },
    {
        path: 'standing-order',
        component: StandingOrder,
        pageTitle: 'Standing Order',
    },
    {
        path: 'standing-order/:id',
        component: StandingOrder,
        pageTitle: 'Standing Order',
    },
    {
        path: 'direct-debit',
        component: DirectDebitContainer,
        pageTitle: 'Direct Debits',
    },
    {
        path: 'direct-debit/:id',
        component: DirectDebitItemContainer,
        pageTitle: 'Direct Debits',
    },
    {
        path: 'make-payment/form',
        component: MakePaymentForm,
        pageTitle: 'Form | Make Payment',
    },
    {
        path: 'make-payment/success',
        component: UKPaymentSuccess,
        pageTitle: 'Status | Make Payment',
    },
    { path: 'make-payment', component: MakePayment, pageTitle: 'Make Payment' },
    {
        path: 'manage-payees',
        component: ManagePayees,
        pageTitle: 'Manage Payees',
    },
    { path: 'add-payee', component: CreatePayee, pageTitle: 'Add Payee' },
    { path: 'add-cash', component: AddCash, pageTitle: 'Add Cash' },
];

export default paymentsRoutes;
