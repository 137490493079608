import { OtpContainer } from 'components/authV2/otp/container/OtpContainer';
import { CheckContainer } from 'components/authV2/check/container/CheckContainer';
import { EmailContainer } from 'components/authV2/email/container/EmailContainer';
import { LoginContainer } from 'components/authV2/login/container/LoginContainer';
import { MainContainer } from 'components/authV2/main/container/MainContainer';
import { ActivatedContainer } from 'components/authV2/activated/container/ActivatedContainer';

import { AccountActivationContainer } from 'components/authV2/accountActivation/container/AccountActivationContainer';

const authRoutes = [
    { path: '', component: MainContainer, pageTitle: 'Login' },
    { path: 'login', component: LoginContainer, pageTitle: 'Login' },
    { path: 'email', component: EmailContainer, pageTitle: 'Login' },
    { path: 'checking/:id?', component: CheckContainer, pageTitle: 'Login' },
    { path: 'otp', component: OtpContainer, pageTitle: 'Login' },
    {
        path: 'activation',
        component: AccountActivationContainer,
        pageTitle: 'Login',
    },
    {
        path: 'activation-success',
        component: ActivatedContainer,
        pageTitle: 'Login',
    },
];

export default authRoutes;
