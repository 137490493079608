import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useForm, usePrevious } from 'lib/utils';

import {
    fetchAccount,
    fetchActivity,
    fetchCards,
    fetchFees,
    fetchPendingTransactions,
    updateSelectedAccount,
} from 'lib/actions';
import {
    getAccountIsPosting,
    getAccountPostSuccess,
    getPostTokenSuccess,
} from 'lib/selectors';
import { hideModal } from 'actions/modal';

const useAccountSelect = (accounts, account) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({
        ppsAccountNo: account.ppsAccountNo,
    });

    const postSuccess = useSelector(getAccountPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const isPosting = useSelector(getAccountIsPosting);
    const jwt = useSelector(getPostTokenSuccess);

    const formattedAccountOptions = accounts.map(account => {
        return {
            label: `${account.bankAccountNo} - ${account.sortCode}`,
            value: account.ppsAccountNo,
        };
    });

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            if (jwt) {
                localStorage.setItem('accessToken', jwt);
            }
            batch(() => {
                dispatch(fetchAccount());
                dispatch(fetchCards());
                // dispatch(fetchAccountsList()); TODO: waiting for new api
                dispatch(fetchPendingTransactions());
                dispatch(fetchActivity(true));
                dispatch(fetchFees());
                dispatch(hideModal());
            });
        }
    }, [postSuccess, prevPostSuccess, dispatch, jwt]);

    const handleSubmit = () => {
        const { ppsAccountNo } = form;

        if (ppsAccountNo) {
            dispatch(updateSelectedAccount(ppsAccountNo));
        }
    };

    return {
        form,
        handleChange,
        formattedAccountOptions,
        handleSubmit,
        isPosting,
    };
};

export default useAccountSelect;
