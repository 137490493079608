import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
    getAccount,
    getAccountIsFetching,
    getAccountError,
    getAccountsList,
    getIsFetchingAccounts
} from 'lib/selectors';
import { fetchAccount, fetchCards, fetchFees } from 'lib/actions';

import Container from 'components/shared/generic/container/presentational/Container';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import AccountOverview from './AccountOverview';
import { useLocation } from 'react-router-dom';
import { hideModal, showModal } from 'actions/modal';
import { ACCOUNT_SELECT } from 'constants/shared/modalTypes';

const AccountOverviewContainer = () => {
    const dispatch = useDispatch();

    const account = useSelector(getAccount);
    const isFetching = useSelector(getAccountIsFetching);
    const isFetchingAccounts = useSelector(getIsFetchingAccounts);
    const error = useSelector(getAccountError);
    const accounts = useSelector(getAccountsList);
    const jwt = localStorage.getItem('accessToken');
    const [showAccountSelect, setShowAccountSelect] = useState(false);

    const refreshAccount = useCallback(() => {
        dispatch(fetchAccount());
    }, [dispatch]);

    const { pathname } = useLocation();

    useEffect(() => {
        if (!pathname === '/more') {
            refreshAccount();
        }
    }, [pathname, refreshAccount, jwt]);

    useEffect(() => {
        if (!jwt) return;
        batch(() => {
            dispatch(fetchAccount());
            dispatch(fetchCards());
            // dispatch(fetchAccountsList()); TODO: waiting for new api
            dispatch(fetchFees());
        });
    }, [jwt, dispatch]);

    return (
        <Container>
            <DataCheck
                data={account}
                isLoading={isFetchingAccounts || isFetching}
                error={error}
            >
                <AccountOverview
                    account={account}
                    handleShowModal={handleShowModal}
                    showAccountSelect={showAccountSelect}
                    accounts={accounts}
                />
            </DataCheck>
        </Container>
    );

    function handleShowModal(e) {
        e.preventDefault();

        setShowAccountSelect(!showAccountSelect);
        dispatch(
            showModal({
                type: ACCOUNT_SELECT,
                props: {
                    handleModalClose: handleModalClose,
                    accounts: accounts,
                    account: account,
                },
            }),
        );
    }

    function handleModalClose(e) {
        e.preventDefault();

        setShowAccountSelect(false);
        dispatch(hideModal());
    }
};

export default AccountOverviewContainer;
