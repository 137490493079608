import { FC } from 'react';
import Mail from '_content/images/icons/more/mail.png';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import Form from 'components/shared/form/presentational/Form';
import Button, {
    ButtonTypes,
} from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';
import { AuthStep } from 'constants/shared/enums';

interface EmailProps {
    error: any;
    handleSubmit: () => void;
    handleChange: () => void;
    code: string;
    remainingSeconds: number;
    handleResend: () => void;
    isCountdownActive: boolean;
}

export const Email: FC<EmailProps> = ({
    handleSubmit,
    handleChange,
    code,
    remainingSeconds,
    handleResend,
    isCountdownActive,
    error,
}) => {
    const translate = useTranslate('email');
    const authStep = localStorage.getItem("authStep");
    const descriptionText = authStep === AuthStep.EMAIL_ACTIVATION_FLOW ? translate('description2') : translate('description');
    return (
        <div className="loginContainer">
            <div className="gradientBox">
                <img alt="mail" src={Mail} className="mb-2" />
                <Typography tag="h1" className="mb-1">
                    {translate('headerText')}
                </Typography>
                <Typography tag="p">{descriptionText}</Typography>
            </div>
            <div className="btnsGr" style={{ height: 70 }}>
                {isCountdownActive ? (
                    <Typography
                        tag="p"
                        styles={{
                            marginTop: 12,
                            marginBottom: 12,
                            width: '100%',
                        }}
                    >{`${translate(
                        'timerText',
                    )} 00:${remainingSeconds
                        .toString()
                        .padStart(2, '0')}`}</Typography>
                ) : (
                    <Button
                        type={ButtonTypes.BUTTON}
                        onClick={handleResend}
                        className="emailBtn"
                    >
                        {translate('resend')}
                    </Button>
                )}
            </div>
            <Form
                className="login-form"
                submitText={translate('form.confirm')}
                isPosting={false}
                onSubmit={handleSubmit}
                error={error}
            >
                <Field>
                    <TextInput
                        classes="generic-input emailCodeInput"
                        name="code"
                        type="text"
                        placeholder={translate('form.code')}
                        value={code}
                        onChange={handleChange}
                        required
                    />
                </Field>
            </Form>
        </div>
    );
};
