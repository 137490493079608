import React from 'react';
import { useDispatch } from 'react-redux';
import { activateCard } from 'lib/actions';

import Button from 'components/shared/generic/button/presentational/Button';
import { hideModal, showModal } from 'actions/modal';
import { CONFIRM_MODAL } from 'constants/shared/modalTypes';
import useTranslate from 'lib/languages/useTranslate';

const IssuedCard = ({ position, card, isDarkText }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cards');

    return (
        <div className={`content issued-card ${isDarkText ? 'dark-text' : ''}`}>
            <i className="icon fa fa-lock-alt"></i>
            <p className="title">{translate('cardSlider.cardNotActivated')}</p>
            {position === 'center' && (
                <Button onClick={showActivateModal}>
                    {translate('cardSlider.activateCard')}
                </Button>
            )}
        </div>
    );

    function showActivateModal(e) {
        e.preventDefault();
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    title: translate('activateCardModal.titleText'),
                    message: translate('activateCardModal.descriptionText'),
                    handleSubmit: activate,
                },
            }),
        );
    }

    function activate() {
        dispatch(hideModal());
        dispatch(activateCard(card.cardSerial));
    }
};

export default IssuedCard;
