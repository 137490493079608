import React from 'react';

import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const CarouselButtons = ({
    onViewPinClick,
    toggleFreeze,
    isFrozen,
    isDisabledCard,
}) => {
    const translate = useTranslate('cards');
    return (
        <div className="carousel-button-container flex center">
            <div className="button-container">
                {/* <LeftArrow onClick={onPrevClick} disabled={start} /> */}
                <div className="buttons">
                    <Button
                        onClick={onViewPinClick}
                        disabled={isDisabledCard}
                        tracking
                        trackingLabel="VIEW_PIN"
                    >
                        <i className="icon fa fa-th" />
                        {translate('viewPin')}
                    </Button>
                    <Button
                        onClick={toggleFreeze}
                        className={`${isFrozen ? 'unfreeze' : 'freeze'}`}
                        disabled={isDisabledCard}
                        tracking
                        trackingLabel={`${
                            isFrozen ? 'UNFREEZE' : 'FREEZE'
                        }_CARD`}
                    >
                        <i
                            className={`icon fa fa-${
                                isFrozen ? 'unlock' : 'lock'
                            }-alt`}
                        />
                        <span>
                            {`${
                                isFrozen
                                    ? translate('unfreezeCard')
                                    : translate('freezeCard')
                            }`}
                        </span>
                    </Button>
                </div>
                {/* <RightArrow onClick={onNextClick} disabled={end} /> */}
            </div>
        </div>
    );
};

export default CarouselButtons;
