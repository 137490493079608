import { combineReducers } from 'redux';
import * as sharedReducers from 'lib/reducers';
import modalReducer from './modal';
import transferWiseQuoteReducer from './tw-quote';
import redirectReducer from './redirect';
import darkModeReducer from './isDarkModeEnabled';
import api from 'lib/services/api';

const appReducer = combineReducers({
    modalReducer,
    redirectReducer,
    transferWiseQuoteReducer,
    darkModeReducer,
    [api.reducerPath]: api.reducer,
    ...sharedReducers,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        const { darkModeReducer } = state;

        state = { darkModeReducer };
    }

    return appReducer(state, action);
};

export default rootReducer;
