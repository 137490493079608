import Typography from 'components/shared/generic/typography/presentational/Typography';
import Form from 'components/shared/form/presentational/Form';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'lib/languages/useTranslate';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import Button, {
    ButtonTypes,
} from 'components/shared/generic/button/presentational/Button';

interface CheckOTPFormProps {
    otp: string;
    handleChange: () => void;
    handleSubmit: () => void;
    handleResendOTP: () => void;
    error: any;
    isPostingOTP: boolean;
    remainingSeconds: number;
    isCountdownActive: boolean;
}
export const Otp = ({
    otp,
    handleChange,
    handleSubmit,
    handleResendOTP,
    error,
    isPostingOTP,
    remainingSeconds,
    isCountdownActive,
}: CheckOTPFormProps) => {
    const translate = useTranslate('oneTimePassword');
    const isDarkModeEnabled = useSelector(getTheme);

    return (
        <div className="loginContainer">
            <Typography tag="h1">{translate('titleText')}</Typography>
            <Typography tag="p">{translate('descriptionText')}</Typography>
            <Form
                className="login-form mt-4"
                buttonSize="full"
                submitText={translate('form.confirm')}
                isPosting={isPostingOTP}
                onSubmit={handleSubmit}
                error={error}
            >
                <Field>
                    <TextInput
                        classes="generic-input"
                        name="otp"
                        type="text"
                        placeholder={translate('form.oneTimePassword')}
                        value={otp}
                        onChange={handleChange}
                        required
                    />
                </Field>
            </Form>
            <div className="btnsGr">
                {isCountdownActive ? (
                    <Typography
                        tag="p"
                        styles={{
                            marginTop: 12,
                            marginBottom: 12,
                            width: '100%',
                        }}
                    >
                        {`${translate(
                            'timerText',
                        )} 00:${remainingSeconds.toString().padStart(2, '0')}`}
                    </Typography>
                ) : (
                    <Button
                        className={`applyBtn ${
                            isDarkModeEnabled ? 'dark' : 'light'
                        }`}
                        size="full"
                        type={ButtonTypes.BUTTON}
                        onClick={handleResendOTP}
                        disabled={isPostingOTP && !error}
                    >
                        {translate('form.resendOTP')}
                    </Button>
                )}
            </div>
        </div>
    );
};
