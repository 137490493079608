import Typography from 'components/shared/generic/typography/presentational/Typography';

import Form from 'components/shared/form/presentational/Form';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'lib/languages/useTranslate';
export const Login = ({
    username,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    const translate = useTranslate('login');

    return (
        <div className="loginContainer">
            <Typography tag="h1">{translate('headerText')}</Typography>
            <Typography tag="p">{translate('description')}</Typography>
            <Form
                className="login-form mt-4"
                submitText={translate('form.confirm')}
                buttonSize="full"
                onSubmit={handleSubmit}
                error={error}
                isPosting={isPosting}
                cancelText={''}
                tracking={false}
            >
                <Field htmlFor={'username'} required>
                    <TextInput
                        required
                        placeholder={translate('form.email')}
                        onChange={handleChange}
                        name="username"
                        type="email"
                        value={username}
                        classes="generic-input"
                    />
                </Field>
            </Form>
        </div>
    );
};
