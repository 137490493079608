import Typography from 'components/shared/generic/typography/presentational/Typography';
import Loading from '_content/images/icons/more/loading.png';
import Button, {
    ButtonTypes,
} from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';
import { getTheme } from 'selectors/darkMode';
import { useSelector } from 'react-redux';

export const Check = ({ error, handleTryAgain }) => {
    const isDarkModeEnabled = useSelector(getTheme);
    const translate = useTranslate("check");
    return (
        <div className="loginContainer checkingContainer d-flex-col-center">
            <div className="checkingBox">
                {!error ? (
                    <div className={`checkingContent ${isDarkModeEnabled ? 'dark-mode' : ''}`}>
                        <Typography tag="h1">{translate('headerText')}</Typography>
                        <img alt="loading" src={Loading} className="mb-2" />
                        <Typography tag="p">{translate('description')}</Typography>
                    </div>
                ) : (
                    <div className={`errorContent ${isDarkModeEnabled ? 'dark-mode' : ''}`}>
                        <Typography tag="h1">{error}</Typography>
                        <Button onClick={handleTryAgain} type={ButtonTypes.BUTTON}>
                            {translate('tryAgain')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
